.hero {
  background-image: url("../../../assets/images/banner.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 80vh;
  width: 100%;
  box-shadow: inset 0 0 0 1000px rgba(64, 64, 59, 0.5);
}

.hero .container {
  padding-top: 5%;
}
.hero h1 {
  color: #fff;
  font-size: 60px;
}
.hero p {
  color: #fff;
  opacity: 0.8;
}
form {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 50px;
  padding: 0 20px;
}
form input {
  padding: 10px;
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.2);
  margin-top: 5px;
  border-radius: 5px;
}
form span {
  font-size: 14px;
  color: grey;
}
input::placeholder {
  font-size: 17px;
  color: black;
}
form .box {
  padding: 15px;
  border-left: 1px solid rgba(128, 128, 128, 0.2);
}
form .box:nth-child(1) {
  border-left: none;
}
form h4 {
  font-weight: 500;
}
@media screen and (max-width: 800px) {
  .hero .container {
    padding-top: 30%;
  }
  form {
    display: flex;
    flex-wrap: wrap;
  }
  form .box {
    border-left: none;
  }
  form input {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .hero {
    background-image: url("../../../assets/images/banner.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 60vh;
    width: 100%;
  }
}

.hero_input_wrap {
  height: 100px;
  padding-top: 30px;
}

.hero_input_wrap button {
  margin-top: -5px;
}

@media only screen and (max-width: 620px) {
  .hero_input_wrap {
    height: 150px;
    padding-top: 30px;
  }

  .hero_input_wrap button {
    margin-top: 10px;
  }
}
