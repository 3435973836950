.xyz_levbitz {
  background: #f8fbff;
  border-radius: 8px;
  box-shadow: 0 25px 98px 0 rgb(0 0 0 / 3%);
  margin-bottom: 20px;
}

.single_property_wrap {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 220px;
  padding-top: 10px;
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  /* .xyz_levbitz {
    background: #f8fbff;
    border-radius: 8px;
    box-shadow: 0 25px 98px 0 rgb(0 0 0 / 3%);
    margin: auto;
    width: 100%;
  }
  .single_property_wrap {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 220px;
    width: 300px;
    padding-top: 10px;
    border-radius: 10px;
  } */
}

.single_property_wrap .featured {
  background: coral;
  margin-left: 2px;
  font-size: 12px;
  color: #fff;
  padding: 2px 5px;
}
.single_property_wrap .sales {
  background: #43a047;
  margin-left: 2px;
  font-size: 12px;
  color: #fff;
  padding: 2px 5px;
}
.single_property_wrap .location {
  background: #757575;
  margin-left: 2px;
  font-size: 12px;
  color: #fff;
  padding: 2px 5px;
}

.single_property_content p {
  color: #bdbdbd;
  font-size: 12px;
  margin-bottom: 10px;
}

.single_property_content h5 {
  font-size: 15px;
}
.single_property_content h6 {
  font-size: 14px;
}
