.topbanner_wrap {
  background-image: url(../../assets/images/banner.png);
  min-height: 35vh;
  padding-top: 15vh !important;
  margin-bottom: 30px;
}

.topbanner_wrap h1 {
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 20px;
  color: #fff;
}
.topbanner_wrap span {
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .topbanner_wrap {
    background-image: url(../../assets/images/banner.png);
    min-height: 30vh !important;
    padding-top: 15vh !important;
  }
  .topbanner_wrap h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
