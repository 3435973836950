.recent_wrap {
  padding: 0px 100px;
}
@media only screen and (max-width: 620px) {
  .recent_wrap {
    padding: 0px 10px;
  }
}

.recent .text {
  padding: 30px;
}
.recent .category span {
  padding: 4px 15px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 13px;
  display: inline-block;
}
.recent .category i {
  font-size: 20px;
  color: #bec7d8;
}
.recent p {
  color: #72809d;
}
.recent .button {
  border-top: 1px solid #e4e8f3;
  padding: 10px 30px;
}

.single_recent_card {
  background-color: #fff;
  box-shadow: 0 25px 98px 0 rgb(0 0 0 / 3%);
}

.single_recent_card img {
  border-radius: 10px;
}
