.lb_nav_wrap {
  background: #fff;
  padding: 0px;
  transition: all 0.4s ease 0s;
  height: 60px;
  display: grid;
  align-items: center;
  box-shadow: none;
  z-index: 99;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  padding: 0px 100px;
}

.lb_active {
  /* background: #094c59; */
  background: #fff;
  padding: 0px;
  transition: all 0.4s ease 0s;
  height: 55px;
  display: grid;
  align-items: center;
  box-shadow: none;
  z-index: 99;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  padding: 0px 100px;
}

@media only screen and (max-width: 600px) {
  .lb_active {
    padding: 0px 0px;
  }
  .lb_nav_wrap {
    padding: 0px 0px;
  }
}

.nav-wrapper {
  width: 98%;
  margin: auto;
}

.myLogo {
  width: 120px;
  padding-top: 0px;
  margin-left: 70px;
}

@media only screen and (max-width: 720px) {
  .myLogo {
    padding-top: -50px;
    margin-left: -5px !important;
  }
  .navbar {
    background: #34acca;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 600px) {
  .navbar {
    background: #064757;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.2rem;
    width: 100% !important;
  }
}

.navbar-logo {
  justify-self: start;
  margin-left: 0px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

@media only screen and (max-width: 600px) {
  .navbar-logo {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    margin-left: -30px !important;
    margin-top: -30px !important;
  }
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(8, auto);

  list-style: none;
  text-align: center;
  /* width: 100vw; */
  justify-content: end;
  margin-right: 2rem;
  /* background-color: #ff0000; */
}

.nav-item {
  display: flex;
  align-items: center;
  height: 50px;

  font-weight: bold;
}

@media screen and (max-width: 960px) {
  .nav-item {
    display: flex;
    align-items: center;

    margin-bottom: 50px;
    font-weight: bold;
  }
}
.nav-menu li:hover {
  background: transparent !important;
}
.nav-links {
  color: #000;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
}

@media screen and (max-width: 960px) {
  .nav-links {
    color: white;
    text-decoration: none;
    font-size: 20px;
    border-radius: 20px;
    text-transform: capitalize;
    /* border: 1px #fff solid; */
    margin-bottom: 50px;
    height: 30px !important;
    padding: 0px !important;
  }
}

.fa-bars {
  color: #000;
  font-size: 20px;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
  margin-left: -40px !important;
  margin-top: -30px !important;
}

@media screen and (max-width: 960px) {
  .menu-icon {
    display: none;
    margin-left: -40px !important;
    margin-top: -30px !important;
  }
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 60vh; */
    position: absolute;
    top: 55px;
    left: -120%;
    opacity: 1;
    transition: all 0.5s ease;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .nav-menu.active {
    /* background: #1a237e; */
    left: 0;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    z-index: 999;
    align-items: left;
    justify-content: "flex-start";
  }

  .nav-links {
    text-align: center;
    padding: 6rem;
    width: 100%;
    display: table;
    margin: auto;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  @media screen and (max-width: 960px) {
    .fa-times {
      color: #fff;
      font-size: 2rem;
      margin-right: -10px;
    }
    .fa-bars {
      color: #fff;
      font-size: 2rem;
      margin-right: -10px;
    }
  }

  button {
    display: none;
  }
}
.account {
  font-weight: bold;
  color: #064757;
  background: #fff;
}
@media only screen and (max-width: 720px) {
  .account {
    font-weight: bold;
    color: #fff;
    background: #064757;

    width: 100%;
  }
}

@media only screen and (max-width: 720px) {
  .myLogo {
    margin-top: 10px;
    margin-left: 10%;
    margin-right: 10%;
  }
}

nav i,
nav [class^="mdi-"],
nav [class*="mdi-"],
nav i.material-icons {
  display: block;
  font-size: 24px;
  height: 0px !important;
  line-height: 56px;
}

.originalAccount {
  border: none;
  outline: none;
  color: #fff;
  background: #064757;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.originalAccount:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
}

.originalAccount:before {
  content: "";
  /* background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  ); */
  /* position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px; */
}

.originalAccount:active {
  color: #000;
}

.originalAccount:active:after {
  background: transparent;
}

.originalAccount:hover:before {
  opacity: 1;
}

.originalAccount:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: #111; */
  background: #ec407a;
  left: 0;
  top: 0;
  border-radius: 10px;
  font-weight: 900;
}

/* @keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
} */

.originalAccountScrolled {
  background-color: #ec407a;
}

@media screen and (max-width: 960px) {
  .originalAccountScrolled {
    background-color: red;
    width: 100% !important;
    margin-bottom: 20px auto !important;
    border-radius: 0px !important;
    margin-left: 0% !important;
  }
}
@media screen and (max-width: 960px) {
  .originalAccount {
    background-color: transparent !important;
    /* border: 1px solid #fff !important; */
    margin-bottom: 20px !important;
    border-radius: 0px !important;
    width: 100%;
    margin-left: 0% !important;
  }
}

@media screen and (max-width: 914px) {
  .nav-menu {
    padding-left: 30px !important;
    min-height: 50vh !important;
    background: #fff;
  }
  .nav-menu span {
    color: #000;
  }
}

/* gjgjgjgjjg */
/* gjgjgjgjjg */
.mybeta {
  font-size: 12px;
  font-weight: bold;
  margin-top: -10px;
  position: absolute;
}

@media screen and (max-width: 600px) {
  .mybeta {
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    margin-top: -10px;
    padding-top: 0px;
    top: 1;
  }
}

.nav_social_icons span {
  margin: 1px 10px;
}
